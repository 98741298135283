import React from 'react';
import { renderItems } from './bulletHelpers';
import Input from './Input';

const Collection = ({ title, contents, _id }) => {
  return (
    <div className="collection">
      <h3>{title}</h3>
      <ul>
        {renderItems(contents, _id)}
        <li>
          <Input collection={_id} />
        </li>
      </ul>
    </div>
  );
};

export default Collection;
