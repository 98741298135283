import React from 'react';
import Task from './Task';
import Event from './Event';
import Note from './Note';

export function renderItems(items, collection) {
  if (!items) {
    return <div>Loading...</div>;
  }
  return items.map(item => {
    var bullet;
    switch (item.kind) {
      case 'task':
        bullet = <Task {...item} key={item._id} collection={collection} />;
        break;
      case 'event':
        bullet = <Event {...item} key={item._id} collection={collection} />;
        break;
      case 'note':
        bullet = <Note {...item} key={item._id} collection={collection} />;
        break;
      default:
        bullet = 'Unrecognized bullet type';
    }

    return bullet;
  });
}
