import React from 'react';
import ReactDOM from 'react-dom';

import Collection from './components/Collection';
import { JournalProvider } from './JournalContext';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      daily_log: {},
      monthly_log: {
        anytime: {},
        days: []
      },
      future_log: {
        anytime: {},
        months: [],
        days: []
      },
      collections: []
    };
    this.API_SERVER_URL = process.env.API_SERVER_URL || '';
    this.updateCollections = this.updateCollections.bind(this);
  }

  componentDidMount() {
    this.updateCollections();
  }

  renderFutureLog() {
    var { future_log } = this.state;
    return Object.keys(future_log).map(key => {
      if (key === 'anytime') {
        return (
          <React.Fragment key={key}>
            {this.renderSingleCollection(future_log[key])}
          </React.Fragment>
        );
      }

      return (
        <React.Fragment key={key}>
          <h3>{key}</h3>
          {this.renderCollections(future_log[key])}
        </React.Fragment>
      );
    });
  }

  renderMonthlyLog() {
    var { monthly_log } = this.state;
    return Object.keys(monthly_log).map(key => {
      if (key === 'anytime') {
        return (
          <React.Fragment key={key}>
            {this.renderSingleCollection(monthly_log[key])}
          </React.Fragment>
        );
      }

      return (
        <React.Fragment key={key}>
          <h3>{key}</h3>
          {this.renderCollections(monthly_log[key])}
        </React.Fragment>
      );
    });
  }

  renderDailyLog() {
    var { daily_log } = this.state;
    return (
      <React.Fragment>{this.renderSingleCollection(daily_log)}</React.Fragment>
    );
  }

  renderCollections(collections) {
    if (!collections) {
      return <div>Loading...</div>;
    }

    return collections.map(item => this.renderSingleCollection(item));
  }

  renderSingleCollection(collection) {
    return <Collection key={collection._id} {...collection} />;
  }

  updateCollections() {
    return fetch(`${this.API_SERVER_URL}/api/v1/collection/`)
      .then(response => response.json())
      .then(data => this.setState(data));
  }

  render() {
    return (
      <div>
        <h1>Bujoy!</h1>
        <JournalProvider
          value={{ ...this.state, updateCollections: this.updateCollections }}
        >
          <h2>Future Log</h2>
          {this.renderFutureLog(this.state.future_log)}
          <hr />
          <h2>Monthly Log</h2>
          {this.renderMonthlyLog(this.state.monthly_log)}
          <hr />
          <h2>Daily Log</h2>
          {this.renderDailyLog(this.state.daily_log)}
          <hr />
          <h2>Collections</h2>
          {this.renderCollections(this.state.collections)}
        </JournalProvider>
      </div>
    );
  }
}

ReactDOM.render(React.createElement(App), document.getElementById('root'));

export default App;
