import React from 'react';
import { JournalConsumer } from '../JournalContext';
import './Input.scss';

class Input extends React.Component {
  constructor(props) {
    super(props);

    var { parent, collection, value } = props;
    this.parent = parent;
    this.collection = collection;
    this.value = value;
    this.buttonLabel = value ? 'Edit' : 'Add';
    this.state = {
      isSaving: false,
      error: null
    };

    this.handleSubmitContext = this.handleSubmitContext.bind(this);
    this.renderInputs = this.renderInputs.bind(this);
    this.showForm = this.showForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
  }

  handleSubmitContext(updateCollections) {
    function createBullet(collection, parent, text, type, edit = false) {
      var API_FETCH_URL = process.env.API_SERVER_URL || '';
      API_FETCH_URL += `/api/v1/collection/${collection}/bullet`;
      var body = { text, type };
      if (edit) {
        API_FETCH_URL += `/${parent}`;
        delete body.type;
      } else if (parent) {
        API_FETCH_URL += `/${parent}/bullet`;
      }

      return fetch(API_FETCH_URL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }).then(response => response.json());
    }

    var handleSuccess = () => {
      console.log('success!');
      this.setState({ isSaving: false, error: null });
      this.props.saveEdit();
    };

    var handleError = err => {
      this.setState({ isSaving: false, error: err });
    };

    var handleSubmit = event => {
      event.preventDefault();
      this.setState({ isSaving: true, error: null });
      var form = event.target;
      var input = event.target.querySelector('input');
      var parentId = input.dataset.parent;
      var collectionId = input.dataset.collection;
      var text = input.value;
      var type = event.target.querySelector('select').value;
      createBullet(
        collectionId,
        parentId,
        text,
        type,
        this.buttonLabel === 'Edit'
      ).then(() => {
        form.reset();
        console.log('reset!');
        updateCollections()
          .then(handleSuccess)
          .catch(handleError);
      });
    };
    return handleSubmit;
  }

  showForm(event) {
    var select = event.target.parentElement.querySelector('select');
    var button = event.target.parentElement.querySelector('button');
    if (event.target.value !== '') {
      if (this.buttonLabel !== 'Edit') {
        select.classList.remove('hidden');
      }
      button.classList.remove('hidden');
    }
  }

  hideForm(event) {
    var select = event.target.parentElement.querySelector('select');
    var button = event.target.parentElement.querySelector('button');
    if (event.target.value === '') {
      if (this.buttonLabel !== 'Edit') {
        select.classList.add('hidden');
      }
      button.classList.add('hidden');
    }
  }

  renderInputs() {
    if (this.state.isSaving) {
      return 'Saving...';
    } else if (this.state.isError) {
      return 'Error...';
    }

    return (
      <React.Fragment>
        <input
          className="bullet-text"
          type="text"
          data-parent={this.parent}
          data-collection={this.collection}
          name="text"
          defaultValue={this.value}
        />
        <select
          name="type"
          id="type"
          defaultValue={this.props.defaultType || 'note'}
          className={this.buttonLabel !== 'Edit' && this.value ? '' : 'hidden'}
        >
          <option value="task">Task</option>
          <option value="event">Event</option>
          <option value="note">Note</option>
        </select>
        <button className={this.value ? '' : 'hidden'}>
          {this.buttonLabel}
        </button>
      </React.Fragment>
    );
  }

  render() {
    return (
      <JournalConsumer>
        {context => (
          <form
            onSubmit={this.handleSubmitContext(context.updateCollections)}
            onChange={this.showForm}
            onBlur={this.hideForm}
          >
            {this.renderInputs()}
          </form>
        )}
      </JournalConsumer>
    );
  }
}

export default Input;
