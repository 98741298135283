import React from 'react';
import { JournalConsumer } from '../JournalContext';
import withBullet from './Bullet';
import Input from './Input';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Bullet.scss';
import './Task.scss';

class Task extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick.bind(this);
    this.handleMigrateSubmit.bind(this);
    this.generateMenu.bind(this);
    this.renderContextMenus.bind(this);

    this.handleEditClick.bind(this);
    this.renderEdit.bind(this);
    this.handleScheduleChange = this.handleScheduleChange.bind(this);
    this.state = {
      newScheduleDate: null
    };
  }

  handleScheduleSubmit(op, updateCollections) {
    function handleIt() {
      var date = this.state.newScheduleDate;
      var month = date.getMonth() + 1;
      var day = date.getDay();
      var year = date.getFullYear();
      sendRequest(collection, _id, op, {
        day,
        month,
        year
      }).then(() => {
        updateCollections()
          .then(() => this.setState({ isSaving: false, error: null }))
          .catch(err => this.setState({ isSaving: false, error: err }));
      });
    }

    var { _id, collection } = this.props;
    return handleIt.bind(this);
  }

  handleScheduleChange(date) {
    this.setState({ newScheduleDate: date });
  }

  handleMigrateSubmit(collections, op, updateCollections) {
    function handleIt(event) {
      sendRequest(collection, _id, op, {
        collection: event.target.parentElement.querySelector('select').value
      }).then(() => {
        updateCollections()
          .then(() => this.setState({ isSaving: false, error: null }))
          .catch(err => this.setState({ isSaving: false, error: err }));
      });
    }

    var { _id, collection } = this.props;
    return handleIt.bind(this);
  }

  renderContextMenus(collections, op, updateCollections) {
    switch (op) {
      case 'migrate':
        return (
          <span className="migrate-menu">
            <select name="collection" id="collection">
              {collections
                ? collections.map(collection => (
                    <option key={collection._id} value={collection._id}>
                      {collection.title}
                    </option>
                  ))
                : ''}
            </select>
            <button
              onClick={this.handleMigrateSubmit(
                collections,
                op,
                updateCollections
              )}
            >
              Submit
            </button>
          </span>
        );
      case 'schedule':
        return (
          <span className="schedule-menu">
            <DatePicker
              selected={this.state.newScheduleDate}
              onChange={this.handleScheduleChange}
            />
            <button onClick={this.handleScheduleSubmit(op, updateCollections)}>
              Submit
            </button>
          </span>
        );
      default:
        return '';
    }
  }

  generateMenu(collections, updateCollections) {
    var { status, _id, collection } = this.props;
    var operations = ['complete', 'migrate', 'schedule', 'delete'];
    var contents;
    switch (status) {
      case 'INCOMPLETE':
        contents = operations.map(op => (
          <span className="context-parent" key={op}>
            <button
              onClick={this.handleClick(updateCollections, collection, _id, op)}
            >
              {op}
            </button>
            {this.renderContextMenus(collections, op, updateCollections)}
          </span>
        ));
        break;
      case 'IRRELEVANT':
      case 'COMPLETED':
        contents = (
          <span>
            <button
              onClick={this.handleClick(
                updateCollections,
                collection,
                _id,
                'reset'
              )}
            >
              reset
            </button>
          </span>
        );
        break;
      case 'MIGRATED':
      case 'SCHEDULED':
        contents = '';
        break;
      default:
        contents = 'Unrecognized Status';
    }
    return <span className="task-menu">{contents}</span>;
  }

  handleEditClick() {
    this.props.showEdit();
  }

  handleClick(updateCollections, collection, _id, op) {
    return event => {
      event.preventDefault();

      if (op === 'migrate' || op === 'schedule') {
        event.target.parentElement.classList.toggle('active');
      } else {
        sendRequest(collection, _id, op).then(() => {
          updateCollections()
            .then(() => this.setState({ isSaving: false, error: null }))
            .catch(err => this.setState({ isSaving: false, error: err }));
        });
      }
    };
  }

  renderEdit() {
    return (
      <li className="bullet task">
        <Input
          parent={this.props._id}
          collection={this.props.collection}
          value={this.props.text}
          defaultType={this.props.kind}
          saveEdit={this.props.saveEdit}
        />
      </li>
    );
  }

  render() {
    if (this.props.isEditing) {
      return this.renderEdit();
    }

    return (
      <JournalConsumer>
        {context => (
          <li
            className={
              this.props.status !== 'INCOMPLETE'
                ? 'bullet task closed'
                : 'bullet task'
            }
            key={this.props._id}
          >
            Task:{' '}
            <a id={this.props._id}>
              <span className="text" onClick={this.handleEditClick.bind(this)}>
                {this.props.text}
              </span>
            </a>{' '}
            <ul>
              <li>
                _id: <span className="id">{this.props._id}</span>
              </li>
              <li>Text: {this.props.text}</li>
              <li>
                Status: {this.props.status}
                {this.generateMenu(
                  context.collections,
                  context.updateCollections
                )}
              </li>
              <li>Postponed: {this.props.timesPostponed}</li>
            </ul>
            {this.props.renderChildren()}
          </li>
        )}
      </JournalConsumer>
    );
  }
}

var API_SERVER_URL = process.env.API_SERVER_URL || '';

function sendRequest(collection, id, op, body = null) {
  var url = `/api/v1/collection/${collection}/bullet/${id}/${op}`;
  return fetch(`${API_SERVER_URL}${url}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: body ? JSON.stringify(body) : undefined
  });
}

export default withBullet(Task);
