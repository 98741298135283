import React from 'react';
import withBullet from './Bullet';
import Input from './Input';
import './Bullet.scss';
import './Note.scss';

class Note extends React.Component {
  handleClick(event) {
    console.log('clicked');
    this.props.showEdit();
  }

  renderEdit() {
    return (
      <li className="bullet note">
        <Input
          parent={this.props._id}
          collection={this.props.collection}
          value={this.props.text}
          defaultType={this.props.kind}
          saveEdit={this.props.saveEdit}
        />
      </li>
    );
  }

  render() {
    if (this.props.isEditing) {
      return this.renderEdit();
    }

    return (
      <li className="bullet note" key={this.props._id}>
        Note:{' '}
        <span className="text" onClick={this.handleClick.bind(this)}>
          {this.props.text}
        </span>{' '}
        <span className="id">{this.props._id}</span>
        {this.props.renderChildren()}
      </li>
    );
  }
}

export default withBullet(Note);
