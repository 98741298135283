import React from 'react';
import Input from './Input';
import './Bullet.scss';
import { renderItems } from './bulletHelpers';

function displayEdit(text) {
  return (
    <li>
      <Input value={text} />
    </li>
  );
}

const withBullet = WrappedComponent => {
  return class Bullet extends React.Component {
    constructor(props) {
      super(props);

      this.renderChildren = this.renderChildren.bind(this);
      this.showEdit = this.showEdit.bind(this);
      this.saveEdit = this.saveEdit.bind(this);
      this.state = { isEditing: false };
    }

    showEdit() {
      console.log('show');
      this.setState({ isEditing: true });
    }

    saveEdit() {
      console.log('save');
      this.setState({ isEditing: false });
    }

    renderChildren() {
      var bullets = renderItems(this.props.children, this.props.collection);

      return (
        <ul>
          {bullets}
          <li>
            <Input parent={this.props._id} collection={this.props.collection} />
          </li>
        </ul>
      );
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          renderChildren={this.renderChildren}
          showEdit={this.showEdit}
          saveEdit={this.saveEdit}
          isEditing={this.state.isEditing}
        />
      );
    }
  };
};

export default withBullet;
